/*
  Ids used on this file:
  /app/assets/images/sprite/icons.svg
*/
export const ICONS_4 = [
  "20-minus",
  "battery",
  "wifi",
  "antenna-connection",
  "16-workflows-v2-community-member-added-to-access-group",
  "16-workflows-v2-community-member-removed-from-access-group",
  "16-workflows-v2-contact-tagged",
  "16-workflows-v2-post-published",
  "16-workflows-v2-post-comment-created",
  "16-workflows-v2-community-member-completed-course",
  "16-workflows-v2-community-member-completed-lesson",
  "16-workflows-v2-entry-rule",
  "16-workflows-v2-community-member-rsvps-to-recurring-event",
  "16-workflows-v2-community-member-leaves-space",
  "16-workflows-v2-contact-untagged",
  "16-workflows-v2-post-liked",
  "16-workflows-v2-community-member-completed-section",
  "16-workflows-v2-community-member-started-paywall-trial",
  "16-workflows-v2-community-member-ended-trial-without-subscription",
  "16-workflows-v2-event-published",
  "16-workflows-v2-community-member-attended-live-event",
  "16-workflows-v2-community-member-email-changed",
  "16-workflows-v2-post-comment-created",
  "16-workflows-v2-community-member-purchases-paywall",
  "16-workflows-v2-community-member-subscribed-to-paywall",
  "16-workflows-v2-community-member-unsubscribed-from-paywall",
  "16-workflows-v2-send-email",
  "16-workflows-v2-send-notification-to-member",
  "16-workflows-v2-send-dm",
  "16-workflows-v2-remove-community-member-from-community",
  "16-workflows-v2-enable-messaging-on-member",
  "16-workflows-v2-disable-messaging-on-member",
  "16-workflows-v2-unsubscribe",
  "16-workflows-v2-add-tag-to-community-member",
  "16-workflows-v2-remove-tag-from-community-member",
  "16-workflows-v2-add-community-member-to-access-group",
  "16-workflows-v2-remove-community-member-from-access-group",
  "16-workflows-v2-add-community-member-to-space",
  "16-workflows-v2-remove-community-member-from-space",
  "16-workflows-v2-add-community-member-to-space-group",
  "16-workflows-v2-remove-community-member-from-space-group",
  "16-workflows-v2-cancel-community-member-paywall-subscription",
  "16-workflows-v2-subscribe-community-member-to-paywall-trial",
  "16-workflows-v2-rsvp-member-to-event",
  "16-workflows-v2-time-delay",
  "16-workflows-v2-community-member-passed-quiz",
  "16-workflows-v2-community-member-failed-quiz",
  "16-workflows-v2-community-member-submitted-quiz",
  "16-workflows-v2-received-form-submission",
  "16-workflows-v2-award-points",
  "16-workflows-v2-send-to-webhook",
  "16-workflows-v2-graph-arrow",
  "20-plus-v3",
  "20-green-circle",
  "20-blue-circle",
  "20-trash-bin",
  "48-ai-bot",
  "16-inbox-unread",
  "120-ai-bot",
  "16-ai",
  "16-thunder-gradient",
  "16-group",
  "14-ai-pencil",
  "16-ai-sources",
  "32-ai-bot",
  "20-ai-bot-gradient",
  "20-bot-customize",
  "20-bot-customize-active",
  "20-bot-behavior",
  "16-workflows-v2-add-audience",
  "20-bot-content",
  "20-bot-content-active",
  "20-book",
  "20-note",
  "24-ai-list-sparkle",
  "24-list",
  "24-note-text",
  "20-normal-behavior-type",
  "20-friendly-behavior-type",
  "20-short-and-sweet-behavior-type",
  "20-descriptive-behavior-type",
  "16-workflows-v2-leveled-down",
  "16-audience",
  "20-audience",
  "20-audience-active",
  "20-mobile-v2",
  "20-tablet-v2",
  "20-desktop-v2",
  "audience-dots",
  "16-sort-icon",
  "16-chevron-triangle-down-small",
  "20-reload",
  "12-indicator",
  "live-users",
  "live-settings",
  "live-chat",
  "live-qa",
  "live-camera",
  "live-camera-off",
  "live-raise-hand",
  "live-leave",
  "live-stop",
  "live-grid",
  "live-screen-share",
  "live-mute",
  "live-unmute",
  "live-go-live",
  "live-indicator",
  "live-link",
  "live-fullscreen",
  "live-fullscreen-exit",
  "live-spinner",
  "28-message-send",
  "14-pin",
  "16-bell-snooze",
  "16-minimize",
  "16-expand",
  "16-ai-snooze",
  "16-ai-agent",
  "16-pause-timer",
  "live-speaker-side-mode",
  "live-speaker-top-mode",
  "live-grid-mode",
  "20-info-circle",
  "24-builder-theme-config",
  "20-builder-light-mode",
  "20-builder-dark-mode",
  "20-builder-text-styles",
  "20-discover",
  "24-arrow-left",
  "24-arrow-right",
  "20-mobile",
  "16-search-v3",
  "20-builder-onboarding-screen",
  "16-20-mobile",
  "20-mobile-settings",
  "new-conversation",
  "16-ai-snooze-v3",
  "20-inbox",
  "20-inbox-unread",
  "20-inbox-snooze",
  "14-moderator-icon",
  "16-interface-link",
  "20-drag-handle",
  "klarna",
  "affirm",
  "afterpay-clearpay",
  "36-ai-snippet",
  "36-ai-file",
  "25-arrow",
  "20-ai-file",
  "20-hometab-unavailable",
  "20-ai-agents",
  "20-ai-knowledge-engine",
  "20-ai-inbox",
  "15-green-checkmark",
  "14-blue-bullet",
  "12-red-close",
  "16-close-v2",
  "16-sort-v2",
  "16-thunder-filled",
  "20-individual-corners",
  "20-all-corners",
  "20-reset-to-default",
  "20-stardust",
  "24-builder-ui-element-media-custom-html",
  "20-globe",
  "20-members-only",
  "24-builder-ui-element-embed-media",
  "24-branded-apps-builder-manage-screens",
  "24-branded-apps-builder-manage-screens-filled",
  "24-branded-apps-builder-design",
  "24-branded-apps-builder-design-filled",
] as const;
